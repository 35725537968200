import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { baseUrl } from 'src/environments/environment';
declare var Swal;

@Injectable({
  providedIn: 'root'
})
export class AltomateService {

  admiEmail = ''

  constructor(
    private platform: Platform,
    private http: HttpClient
  ) { }

  getEmail(){
    return this.admiEmail
  }

  setEmail(val){
    this.admiEmail = val
  }

  showMessage(title, description, icon) {
    Swal.fire({
      title: title,
      text: description,
      icon: icon,
      showCancelButton: false,
      timerProgressBar: false,
    })
  }

  showContent(title, element) {
    Swal.fire({
      title: title,
      html: element,
      timer: 10000,
    }).then(() => {
      // this.nav.pop();
    })

    console.log(1)
  }


  _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  swal_button(title, text, icon) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: title,
        text: text,
        icon: icon,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Yes",
        cancelButtonText: "No"
      }).then((result) => {
        if (result.isConfirmed) {
          resolve('Confirm')
        } else {
          resolve('No')
        }
      })
    })
  }

  swalclose() {
    Swal.close()
  }


  widther() {
    return this.platform.width() >= 768 ? 'desktop' : 'mobile'
  }

  fileChange(event) {
    return new Promise((resolve, reject) => {
      if (event.target.files && event.target.files[0] && event.target.files[0].size < (10485768)) {

        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result.toString().replace(';base64,', "thisisathingtoreplace;")
          let imgarr = base64String.split("thisisathingtoreplace;")
          event.target.value = ''

          resolve({ success: true, data: { image: reader.result.toString(), files: file, base64String: imgarr[1] } })
        };

        reader.readAsDataURL(file);



        // var canvas = <HTMLCanvasElement>document.createElement("canvas");
        // var ctx = canvas.getContext("2d");
        // var cw = canvas.width;
        // var ch = canvas.height;
        // var maxW = 400;
        // var maxH = 400;
        // var type = event.target.files[0].type;
        // var file = event.target.files[0]
        // var img = new Image;
        // img.onload = () => {
        //   var iw = img.width;
        //   var ih = img.height;
        //   var scale = Math.min((maxW / iw), (maxH / ih));
        //   var iwScaled = iw * scale;
        //   var ihScaled = ih * scale;
        //   canvas.width = iwScaled;
        //   canvas.height = ihScaled;
        //   ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
        //   event.target.value = ''


        //   let imagec = canvas.toDataURL();
        //   let imgggg = imagec.replace(';base64,', "thisisathingtoreplace;")
        //   let imgarr = imgggg.split("thisisathingtoreplace;")
        //   let base64String = imgarr[1]

        //   resolve({ success: true, data: { image: imagec, files: file, base64String: base64String } })
        // }

        // img.src = URL.createObjectURL(event.target.files[0]);

      } else {
        reject({ success: false, message: '"Your Current Image Too Large, " + event.target.files[0].size / (10241024) + "MB! (Please choose file lesser than 8MB)"' })
        alert("Your Current Image Too Large, " + event.target.files[0].size / (10241024) + "MB! (Please choose file lesser than 8MB)")
      }
    })
  }
  

  pictureToLink(folder, file, extraParametes) {

    return new Promise((resolve, reject) => {
      if (file != null && file != undefined && file != '') {

        let formData = new FormData();  
        formData.append('file', file, file.name);

        const randomKey = Date.now().toString(36).substring(0, 5) + Math.random().toString(36).substr(2).substring(0, 4)

        formData.append("fileName", (randomKey + file.name));


        this.http.post(baseUrl + '/uploadFileToGS', formData, {}).subscribe((res) => {
          resolve({ success: true, link: res['Location'], extraParametes: extraParametes })
        })


      }
    })
  }

  base64ToLink(folder, base64) {
    return new Promise((resolve, reject) => {
      if (base64 != null && base64 != undefined && base64 != '') {

        if (base64.startsWith('https://')) {
          resolve({ success: true, link: base64 })
          return
        }

        let splitArr = base64.replace(';base64,', "thisisathingtoreplace;").split("thisisathingtoreplace;")

        const type = splitArr[0].split(';')[0].split('/')[1];

        let documentExtension = splitArr[0].split(';')[0].split('/')[0] == 'data:image' ? type : 'pdf'

        const randomKey = Date.now().toString(36).substring(0, 5) + Math.random().toString(36).substr(2).substring(0, 4) + '.' + documentExtension

        this.http.post(baseUrl + '/uploadBaseToSG', { base64: splitArr[1], fileName: randomKey, contentType: (splitArr[0].split(';')[0].split('/')[0] == 'data:image' ? `image/${type}` : 'application/pdf') }, {}).subscribe((res) => {
          resolve({ success: true, link: res['Location'] })
          return
        })


      }
    })
  }

  isNullOrEmpty(...args: any[]) {
    let temp = args[0];

    if (temp === undefined || temp === null || temp === '') {
      return true;
    }

    if (typeof temp === 'string') {
      if (temp === undefined || temp === null || temp === '') {
        return true;
      }

      return false;
    }

    if (typeof temp === 'object') {
      let variable = args[1];

      for (let i = 0; i < variable.length; i++) {
        if (
          temp[variable[i]] === undefined ||
          temp[variable[i]] === null ||
          temp[variable[i]] === ''
        ) {
          return true;
        }
      }

      return false;
    }
  }

  emailValidator(email) {
    if (email) {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    } else {
      return false;
    }
  }

}
