import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AltomateService } from './altomate.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanLoad {

  constructor(private nav: NavController, private altomateService: AltomateService) {

  }
  

  canLoad(
    route: Route,
    segments: UrlSegment[]): Promise<boolean> {
    return new Promise((resolve) => {

      if (localStorage.getItem('adminDetails') == null || localStorage.getItem('adminDetails') == '' || localStorage.getItem('adminDetails') == undefined) {
        resolve(false)
        this.nav.navigateRoot('login')
      } else {
        const adminDetails = JSON.parse(localStorage.getItem('adminDetails'))
        this.altomateService.setEmail(adminDetails['email'])
        resolve(true)
      }


    })
  }
}
