// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyAnEFcHEaiJA3HdjkmW7UlPQzyo-M8sw-s",
  authDomain: "cwceng-entitymanagement.firebaseapp.com",
  databaseURL: "https://cwceng-entitymanagement-default-rtdb.firebaseio.com",
  projectId: "cwceng-entitymanagement",
  storageBucket: "cwceng-entitymanagement.appspot.com",
  messagingSenderId: "204373321923",
  appId: "1:204373321923:web:7c910e21dc9f3ba9bc9a76"
};

export const baseUrl = 'https://us-central1-cwceng-entitymanagement.cloudfunctions.net/widgets3'


// export const FIREBASE_CONFIG = {
//   apiKey: "AIzaSyCbKA3oCYUcKv4XQb8g8t0dzxFEAzoLn04",
//   authDomain: "fusioneta-test.firebaseapp.com",
//   databaseURL: "https://fusioneta-test-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "fusioneta-test",
//   storageBucket: "fusioneta-test.appspot.com",
//   messagingSenderId: "167207020456",
//   appId: "1:167207020456:web:d199f2e965a4a037e39f9d",
//   measurementId: "G-D4PKN9T6VB"
// };

// export const baseUrl =  'https://us-central1-fusioneta-test.cloudfunctions.net/widgets3'





/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
