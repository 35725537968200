import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './login.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'template-list',
    loadChildren: () => import('./template-list/template-list.module').then( m => m.TemplateListPageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'template-edit',
    loadChildren: () => import('./template-edit/template-edit.module').then( m => m.TemplateEditPageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'admin-list',
    loadChildren: () => import('./admin-list/admin-list.module').then( m => m.AdminListPageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'admin-create',
    loadChildren: () => import('./admin-create/admin-create.module').then( m => m.AdminCreatePageModule)
  },
  {
    path: 'admin-edit',
    loadChildren: () => import('./admin-edit/admin-edit.module').then( m => m.AdminEditPageModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'pdf-document',
    loadChildren: () => import('./pdf-document/pdf-document.module').then( m => m.PdfDocumentPageModule)
  },  {
    path: 'notification',
    loadChildren: () => import('./notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'promocode-list',
    loadChildren: () => import('./promocode-list/promocode-list.module').then( m => m.PromocodeListPageModule)
  },
  {
    path: 'notification-create',
    loadChildren: () => import('./notification-create/notification-create.module').then( m => m.NotificationCreatePageModule)
  },
  {
    path: 'notification-edit',
    loadChildren: () => import('./notification-edit/notification-edit.module').then( m => m.NotificationEditPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
